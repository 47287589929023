import React from "react";
import styles from "./shareButtons.module.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";



import { faTwitterSquare, faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const ShareButtons = (props: { url: string, text: string }) => {
  return <div className={styles.shareButtons}>
    <FacebookShareButton url={props.url} >
      <FontAwesomeIcon icon={faFacebook} />
    </FacebookShareButton>
    <LinkedinShareButton url={props.url}><FontAwesomeIcon icon={faLinkedin} /></LinkedinShareButton>
    <TwitterShareButton url={props.url}><FontAwesomeIcon icon={faTwitterSquare} /></TwitterShareButton>
  </div>
}