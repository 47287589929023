import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Layout from "../components/layout";
import SEO from "../components/seo";
import styles from "./blog-post.module.css";
import { ShareButtons } from "../components/shareButtons/shareButtons";
import Comments from "../components/comments/comments";

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const contentRef = React.createRef<HTMLElement>();
  const featuredImage = post.frontmatter.featuredImage?.childImageSharp.fluid;
  const postsNav = <nav className={styles.blogPostNav}>
  <ul>
    <li>
      {previous && (
        <Link to={previous.fields.slug} rel="prev">
          ← {previous.frontmatter.title}
        </Link>
      )}
    </li>
    <li>
      {next && (
        <Link to={next.fields.slug} rel="next">
          {next.frontmatter.title} →
        </Link>
      )}
    </li>
  </ul>
</nav>;
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={featuredImage.src}
      />
      <article
        className={styles.blogPost}
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className={styles.headerWrapper}>
          <header className={styles.header}>
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            {post.frontmatter.introduction && <p>{post.frontmatter.introduction}</p>}
            {/* <p>{post.frontmatter.date}</p> */}
            <a onClick={() => {contentRef.current.scrollIntoView(true) }}>
                  <FontAwesomeIcon icon={faChevronDown}/>
              </a>
          </header>
          <div className={styles.blogLink}>
            <Link to="/blog">← All articles</Link>
          </div>    
          {featuredImage 
            ? <Img fluid={featuredImage} className={styles.headerImage}/> 
            : <div className={styles.headerImage}/>
          }
        </div>
        <div className={styles.postMeta}>
          <ShareButtons url={location.href} text={post.frontmatter.title} />
        </div>
        <section className={styles.postText} ref={contentRef}
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
      <div className={styles.postMeta}>
          <ShareButtons url={location.href} text={post.frontmatter.title} />
          <Comments />

      </div>
      {postsNav}
      
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        introduction
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
