import React, {Component} from "react";
import styles from "./comments.module.css";
export default class Comments extends Component {
  private commentBox;
  constructor(props){ 
    super(props);
    this.commentBox = React.createRef(); // Creates a reference to inject the <script> element
  }
  componentDidMount () {
      const utteranceTheme = "github-light";
      let scriptEl = document.createElement("script");
      scriptEl.setAttribute("src", "https://utteranc.es/client.js");
      scriptEl.setAttribute("crossorigin","anonymous");
      scriptEl.setAttribute("async", "true");
      scriptEl.setAttribute("repo", "kpkozak/kkozak-pl-comments");
      scriptEl.setAttribute("issue-term", "pathname");
      scriptEl.setAttribute( "theme", utteranceTheme);
      this.commentBox.current.appendChild(scriptEl);
  }

  render() {
    return (
        <div>
          <h2>Comments</h2>
          <hr />
          <div ref={this.commentBox} className={styles.commentsWrapper}/>
        </div>
    );
  }
}